body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  background-color: var(--body-bg-color);
  /*background-color: #EFE9E4;*/
  color: var(--font-color);
  
}

*,
*::before,
*::after {
  cursor: none;
}

/* Themes */
.dark {
  --font-color: #F0EBE5;
  --body-bg-color: rgb(25 25 25);
  --font-family: Metropolitan;
  --alt-bg-color:#444444;
  --alt-light: rgba(240, 235, 229, 0.7);
  /*
  --font-color: #edece7;
  --body-bg-color: #997950;
  --alt-bg-color: #444444;
  --font-family: BlackDelights;
  */
  
}

.light {
  --font-color: #5b4a3b;
  --body-bg-color: #edece7;
  --alt-bg-color: #444444;
}

.theme-button {
  position: absolute;
  top: 0;
  font-size: 2rem;
  font-weight: 100;
  margin-top: 10px;
  margin-left: 10px;
}

@font-face {
  font-family: "BlackDelights";
  src: local("BlackDelights"), url("../src/assets/fonts/black-delights-font/BlackDelights-L348y.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Metropolitan";
  src: local("Metropolitan"), url("../src/assets/fonts/metropolis-font/MetropolisPersonalUseRegular-nR5LY.ttf");
  font-weight: normal;
}

/* Animations */
@keyframes border-circle-animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100vw);
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes line-draw {
  0% {
    width: 0%;
  }
  50% {
    border-bottom: 1px solid var(--body-bg-color);
  }
  100% {
    border-bottom: 1px solid var(--body-bg-color);
    width: 75%;
  }
}

@keyframes move-text {
  0% { bottom: -0.5em; opacity: 1; }
  50% { bottom: 0em; }
  100% { bottom: 0; opacity: 1; }
}

@keyframes textAnimation {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes textReveal {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  50% {
    opacity: 0.50;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes draw-line {
  0% {
    width: 0;
  }
  50% {
    width: 50%;
   
  }
  100% {
    width: 100%;
    
  }
}
/* Animations Ends */

/* Misc Styling */ 
.small-top-margin {
 margin-top: 1em;
}

.container-large {
  height: 95vh;
}

.padding-left {
  padding-left: 30%;
}

.padding-bottom {
  padding-bottom: 50%;
}

/* Text Styling */ 
.slideUp-text {
  transform: scaleX(20deg);
 /* animation: textAnimation 2s forwards;
  animation-delay: 1s;*/
}

.text-reveal {
  animation: textReveal 4s forwards;
}

.text-opacity {
  opacity: 0;
}

.slide-out {
  animation: slideLeft 5s forwards;
}

.hidden-text {
  display: none;
}

.small-text {
  font-size: 14px;
  font-family: Metropolitan;
}

.medium-text {
  font-size: 30px;
  font-weight: 400;
  font-family: BlackDelights;
}

.large-text {
  font-size: 2rem;
  font-family: Metropolitan;
  font-weight: 100;;
  line-height: 1em;
}

.text-center {
  text-align: center;
  text-align: -webkit-center;
}

.no-underline {
  text-decoration: none;
}

.bold {
  font-weight: 600;
}

.lightweight {
  font-weight: 300;
}

/* Flex Styling */
.flex {
  display: flex;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-end {
  justify-content: flex-end;
}
/* Flex Styling Ends*/

/* Background Styling */ 
.transparent {
  background-color: transparent;
}

.background-transition {
  transition: transform 3s;
}

.background-slideup {
  transform: translateY(0%);
  background-color: var(--font-color);
}

.background-light {
  background-color: var(--font-color);
  color: var(--body-bg-color);
}
/* Background Styling Ends */



/* Misc Styling */

/* Marquee Styling */
.marquee-flex {
  display: flex;
  flex-direction: row;
  background-color: transparent;
}

.marquee {
  background-color: transparent;
  position: relative;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  font-family: BlackDelights;
  font-weight: 100;
  font-size: 5vw;
}

.marquee-p-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

}

.marquee-p {
  padding: 2rem;
  z-index: 999;
  background-color: transparent;
  text-decoration: none;
  font-weight: 200;
}

.hidden-div {
  font-size: 24px;
  font-weight: 600;
  width: 100vw;
  padding: 5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: absolute;
  /*-webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content:space-between;*/
  align-items: center;
  z-index: 9999;
  overflow-x: hidden;
}
/* Marquee Ends */


/* Loading Page Styling */
.loading-page { 
  height: 100vh;
  overflow-y: hidden;
  background-color: var(--font-color);
  color:var(--alt-bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader {
  height: 0.5px;
  width: 0;
  background-color: black;
  animation: draw-line 2s forwards;
}

.loading-text {
  background-color: var(--font-color);
  color: var(--body-bg-color);
  font-size: 16px;
}


/* Loading Page Ends */


/* Home Styling */

.text-animation {
  position: relative;
  display: inline-block;
  opacity: 0;
  animation: textAnimation 2s forwards;
  animation-delay: 1s;
}

.text {
  color: var(--font-color);
  font-size: 3vh;
  font-weight: 300;
}

.home-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-title-container {
  padding: 25px;
  height: 100vh;
  
}

.home-title {
  font-size: 6vw;
  margin-top: 10rem;
  margin-left: 6rem;
  font-family: Metropolitan;
  font-weight: 100;
}

.home-title2 {
  font-size: 15vw;
  margin-top: -3rem;
  
  font-family: Metropolitan;
  font-weight: 100;
}


.profile-pic {
  opacity: 0;
  border: 1px solid var(--alt-bg-color);
  object-fit: cover;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  margin-right: 6rem;
  margin-left: auto;
  margin-top: 0px;
  margin-bottom: 50px;
  float: right; 
  transition: width 2s, height 2s, transform 2s;
  animation: textReveal 2s forwards;
  animation-delay: 1s;
}

/* Home Styling Ends */

/* Nav Styling */ 
a span span {
  position: relative;
  opacity: 0;
  animation: move-text 1s forwards;
  /* animation: move-text 1s forwards;*/
  animation-iteration-count: 1;
}

.close-btn {
  border: none;
  margin-right: 10px;
  z-index: 99999;
  position: relative;
  background-color: transparent;
}

.change {
  background-color: transparent;
}

.change .close-btn {
  
}

.change .close-line1 {
  transform: translateY(12px) rotate(45deg);
  background-color: #edece7;
}

.change .close-line2 { 
  height: 1px;
  margin: 10px 0;
  width: 30px;
  transition: .4s;
  transform: translateY(1px) rotate(-45deg);
  background-color: #edece7;
}

.close-line1, .close-line2 {
  background-color: var(--font-color);
  height: 1px;
  margin: 10px 0;
  width: 30px;
  transition: .4s;
}

.hidden-menu {
  display: none;
  transition: 1s;
}

.menu-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  background-color: var(--alt-bg-color);
  border-radius: 25px; 
  transition: 0.4;
  padding: 35px 30px;
}

.menu-content {
  background-color: var(--alt-bg-color);
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  margin-top: 20px;
  
}

.menu-link {
  background-color: var(--alt-bg-color);
  font-family: Metropolitan;
  font-size: 16px;
  text-decoration: none;
  
}

.menu-link:hover {
  text-decoration: underline; 
}

.menu-link span {
  background-color: var(--alt-bg-color);
  color: #edece7;
}

.social-link-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 1rem;
  padding-right: 1rem;
}

.social-links {
  color: var(--font-color);
  text-decoration: none;
  font-size: 10vw;
  border: none;
  font-family: Metropolitan;
}

.social-links:hover {
  text-decoration: underline;
  
}

a span span {
  position: relative;
  opacity: 0;
  animation: move-text 1s forwards;
  /* animation: move-text 1s forwards;*/
  animation-iteration-count: 1;
}

/* Nav Ends */

/* About Styling */
.border-circle-animation {
  width: 200px;
  height: 200px;
  border: 2px solid white;
  border-radius: 50%;
  position: relative;
  animation: border-circle-animation 3s infinite linear;
}

.line-div {
  animation: draw-line 2s forwards;
  color: white;
}

.education-container, .skills-container, .work-container, .about-me-container, .about-me-container2 {
  padding: 5rem;
}

.about-me-container {
  height: 95vh;
  
}

.about-me-text {
  margin-top: 0px;
  font-size: 30px;
  font-family: Metropolitan;
}

.about-me-text2 {
  font-size: 4vw;
}

.about-em {
  font-family: BlackDelights;
  font-size: 32px;
}


.about-scrolled {
  background-color: var(--alt-bg-color);
  color: var(--body-bg-color);
  transition: 2s;
}

.scrolled2 {
  background-color: #444444;
  color: #FFFAF7;
  transition: 4s;
}

.skills-content {
  font-weight: 400;
}

.skills-edu-container {

}

/* About Styling Ends */

/* Work Styling Start */
.numbers {
  font-size: 180px;
  font-family: BlackDelights;
}

.work-hidden-container {
  font-size: 1vw;
}

.work-image-before {
  display: none;
  width: auto;
  height: 20rem;
}

.work-image {
  left: 0;
  width: auto;
  height: 20rem;
  filter: grayscale(100%);
}

.work-image:hover {
  filter: none;
  -webkit-filter: grayscale(0);
}

.work-image-ani {
  animation: textReveal 5s forwards;
}

.work-image-container {
  display: flex;
  flex-direction: row;
}

.work-title {
  color: var(--body-bg-color);

  background-color: transparent;
  margin-right: 2rem;
}

.work-wrapper {
  padding: 50px;
}

.work-arrow {
  position: absolute;
  left: 0;
}

.work-container {
  overflow-x: hidden;
}





/* Work Styling Start */

/* Cursor Styling */
.large-cursor-dark {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 0.5px solid black;
  background-color: transparent;
  position: fixed;
  left: 0;
  top: 0;
  transform: translate(-140px, -140px);
  z-index: 99999999999;
}

.large-cursor-light {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 0.5px solid white;
  background-color: transparent;
  position: fixed;
  left: 0;
  top: 0;
  transform: translate(-140px, -140px);
  z-index: 99999999999;
}
/*
.custom-cursor {
  position: fixed;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--font-color);
  background-color: transparent;
  mix-blend-mode: difference;
  z-index: 99999999999;
  pointer-events: none;
}
*/


.cursor-light {
  position: fixed;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid white;
  background-color: white;
  z-index: 99999999999;
  pointer-events: none;
}

.cursor-light-hover {
  position: fixed;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid white;
  background-color: transparent;
  z-index: 99999999999;
  pointer-events: none;
}

.cursor-dark {
  position: fixed;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: black;
  z-index: 99999999999;
  pointer-events: none;
}

.cursor-dark-hover {
  position: fixed;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: transparent;
  z-index: 99999999999;
  pointer-events: none;
}

.cursor {
  position: fixed;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid var(--font-color);
  background-color: var(--font-color);
  z-index: 99999999999;
  pointer-events: none;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vw;
}

.title {
  font-size: 15vw;
  font-weight: 100;
  font-style: italic;
  text-align: center;
}

/* Contact Styling */
.contact-close-line1 {
  height: 1px;
  margin: 10px 0;
  width: 30px;
  transition: .4s;
  transform: translateY(0px) rotate(-45deg);
  background-color: var(--font-color);
}

.contact-close-line2 {
  height: 1px;
  margin: 10px 0;
  width: 30px;
  transition: .4s;
  background-color: var(--font-color);
  transform: translateY(-11px) rotate(45deg);
}



.scroll-down {
  opacity: 1;
  font-size: 8vw;
  transition: 1s;
  animation: slideIn 5s forwards; 
}

.scroll-up {
  opacity: 0;
  animation-delay: 2s;
  transition: 3s;
  animation: slideLeft 5s forwards; 
}

.slide-text {
  transform: translateX(-100vw);
}

.hidden-form {
  display: none;
}

.contact-form {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 99999999;
}

.contact-form-content {
  opacity: 0.7;
  margin-top: 200px;
}

.contact-title {
  font-family: BlackDelights;
  font-size: 5vw;
  font-weight: 300;
}

.contact-container {
  padding: 5rem;
  
}

.form-content {

}

.form-input {
  border: none;
  font-family: Metropolitan;
  height: 2.5rem;
  width: 75vw;
}

.form-input-message {
  border:none;
  font-family: Metropolitan;
  height: 5rem;
  width: 75vw;
}

.form-button {
  border: 1px solid var(--font-color);
  border-radius: 50px;
  margin-top: 1rem;
  width: 10rem;
  height: 2rem;
}

.contact-line {
  border-bottom: 0.5px solid var(--font-color);
  width: 75vw;
}

.footer-container {
  padding: 4rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content:space-between;
  background-color: var(--font-color);
}

.footer-links {
  background-color: var(--font-color);
  color:var(--body-bg-color);
}

.social-container {
  width: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content:space-between;
  background-color: var(--font-color);
}

/* Mobile Styling */ 
@media only screen and (max-width: 600px) {
  * {
    cursor: auto;
  }
  .cursor-dark, .cursor-light .large-cursor-dark, .large-cursor-light {
    display: none;
  }
  .theme-button {
    font-size: 6vw;
  }
  .home-title {
    margin-left: 3rem;
    font-size: 4vw;
  }
  .home-title2 {
    margin-top: -1rem;
  }
  .menu-link {
    
  }
  .profile-pic {
    width: 300px;
    height: 300px;
  }
  .home-intro-container {
    padding: 3rem;
  }
  .form-input {
    height: 3rem;
  }
 
  .work-title {
    margin-right: 0rem;
  }
  .work-image-container {
    flex-direction: column-reverse;
  }
  .work-image {
    display: none;
  }
  .work-wrapper {
    padding: 0px;
  }
  .slide-text {
    margin-left: 2rem;
  }
  .education-container, .skills-container, .work-container, .about-me-container, .about-me-container2 {
  padding: 3rem;
  }
  .contact-container {
    padding: 3rem;
  }
}

@media only screen and (device-width: 768px) {
  /* For general iPad layouts */
  * {
    cursor: auto;
  }
  .cursor {
    display: none;
  }
  .cursor-dark, .cursor-light .large-cursor-dark, .large-cursor-light {
    display: none;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  /* For portrait layouts only */
  * {
    cursor: auto;
  }
  .cursor {
    display: none;
  }
  .cursor-dark, .cursor-light .large-cursor-dark, .large-cursor-light {
    display: none;
  }
  .work-image {
    width: 20rem;
    height: auto;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
  /* For landscape layouts only */
  * {
    cursor: auto;
  }
  .cursor-dark, .cursor-light .large-cursor-dark, .large-cursor-light {
    display: none;
  }
  .cursor {
    display: none;
  }
}
